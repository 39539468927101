<template>
    <div id="contact-form">
        <div class="row-start-2 lg:row-start-1">
            <slot name="subtitle">
                <UIHeading tag="h1" role="subtitle" class="mb-2.5 md:mb-4">
                    <slot name="subtitle-text">Contact form</slot>
                </UIHeading>
            </slot>

            <slot name="title">
                <UIHeading tag="h2" class="mb-2.5">
                    <slot name="title-text">Schedule Your Service Today!</slot>
                </UIHeading>
            </slot>

            <div class="text-lg text-slate-800 font-semibold">
                <slot name="head-text">Fill out this form and one of our friendly technician will get back in touch with you shortly.</slot>
            </div>
        </div>

        <div class="grid lg:grid-cols-[288px_1fr] gap-9 lg:gap-10 mt-10">
            <slot name="aside"></slot>
            <slot name="main"></slot>
        </div>
    </div>
</template>
<script setup lang="ts">
</script>
